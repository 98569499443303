<template>
  <div class="box-body">
    <ul class="timeline timeline-inverse" v-for="history_item in employee_history">
      <li class="time-label">
        <span class="bg-blue">
          {{ history_item.created_date }}
        </span>
      </li>
      <li>
        <div class="timeline-item">
          <h3 class="timeline-header" style="word-break: break-all;">
            <strong style="font-weight: 600;">
              {{ history_item.action_type }}: {{ history_item.details }}
            </strong>

          </h3>
          <div class="timeline-body" v-if="history_item.creator">
            <p class="font-small pull-right">
              Автор:
              <span>
                <strong>{{ history_item.creator.name }}</strong> (ID {{ history_item.creator.id }})
              </span>
            </p>

            <br/>
          </div>
        </div>
      </li>
      <li>
        <i class="fa fa-clock-o bg-gray"></i>
      </li>
    </ul>
    <div class="panel panel-default" v-if="employee_history.length === 0">
      <div class="panel-body text-center text-muted mt-5 mb-5">
        Нет элементов для отображения
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'employee-history',

  props: {
    employeeId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    employee_history: [],
  }),

  async created() {
    try {
      const employee_history_response = await API.apiClient.get(
          '/employee/' + this.employeeId + '/history'
      );

      this.employee_history = employee_history_response.data;
    } catch (error) {
      errorHandler(error);
    }
  },
}
</script>

<style scoped>

</style>
